/* ============= global CSS styles ==================== */
body {
  overflow: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Source Code Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hidescroll {
  overflow: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(62, 62, 62);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}
.display {
  opacity: 1 !important;
}
.container {
  width: auto;
  max-width: 1300px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.contact .row {
  width: 100%;
}
.row {
  display: flex;
  z-index: 1;
}
.img-fluid {
  max-width: 60%;
  width: 50%;
}
.text-center {
  text-align: center;
}
.bgred {
  background-color: red;
}
.btn {
  outline: 0;
  border: 0;
  font-size: 20px;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  z-index: 1;
  background-color: #01bf71;
  padding: 10px 50px;
  margin: 0 0.5rem;
}

.col-md-12 {
  width: 100%;
}
.col-md-6 {
  width: 49.9%;
}
.col-md-3 {
  margin: 0 1rem !important;
  width: 100%;
}

.col-md-4 {
  width: 33.3%;
}
.col-md-4:first-child {
  margin-right: 1rem;
}
.col-md-4:last-child {
  margin-left: 1rem;
}

.x {
  margin: 0rem 1rem !important;
  width: 33.3%;
}
ul li a {
  cursor: pointer;
}
.s {
  width: 100%;
  margin: 0 1rem !important;
}
/* ============= global CSS styles ==================== */

/* ======== main component ============ */
.main-title {
  font-size: 48px;
  color: #01bf71;
}
.main-p {
  font-size: 22px;
  font-weight: 500;
}
main {
  padding: 3rem 0;
  background-color: #fafafa;
  width: 100%;
}
/* ======== main component ============ */

/* ====== media query ======= */

/* ========== responsive for large screen ================= */
@media (max-width: 1300px) {
  .container {
    max-width: 1300px;
  }
  .card {
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
    /* height: 100%; */
    transition: all 0.2s;
    background-color: white;
    box-shadow: 5px 5px 5px 5px #e5e5e5;
    margin-bottom: 20px;
  }
  .card-cover {
    flex-direction: column;
    gap: 1rem;
  }
}

/* ========== responsive for medium screen ================= */
@media (max-width: 992px) {
  header p {
    font-size: 18px;
    text-align: center !important;
  }
  main {
    padding: 3rem 0;
    background-color: white;
    overflow: hidden;
}
  .col-md-4 {
    width: 49.9%;
  }
  .navbar ul {
    margin-left: 4.5rem;
  }
  .container {
    max-width: 960px;
  }
  .col-md-3 {
    width: 100%;
  }

  .side1 .row {
    display: inline-block;
    width: 100%;
  }
  .side2 .row {
    display: inline-block;
    width: 100%;
  }
  .about {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 3rem;
  }
  .about .about_content {
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    word-spacing: 5px;
  }
  .about .about_image {
    width: 400px;
    height: 400px;
    object-fit: cover;
  }

  .process {
    flex-direction: column;
    justify-content: center;
  }

  .process .main-title {
    text-align: center;
    margin-top: 6rem;
  }
  .process .process_image {
    width: 400px !important;
  }

  .process .process_content {
    font-size: 20px;
    font-weight: 500;
  }

  .choose {
    flex-direction: column;
    justify-content: center;
  }

  .choose .main-title {
    text-align: center;
    margin-top: 6rem;
  }
  .choose .choose_image {
    width: 390px !important;
  }

  .choose .choose_content {
    font-size: 20px;
    font-weight: 500;
  }
}

/* ========== responsive for small  screen ================= */
@media (max-width: 768px) {
  header h1 {
    text-align: center;
    font-size: 44px !important;
  }
  .container {
    max-width: 720px;
  }
  .navbar ul {
    margin-left: 0rem;
    list-style: none;
  }
  .col-md-4 {
    width: 55.9% !important;
  }
  .services .col-md-4 {
    margin: 1rem 0;
  }
}

/* ========== responsive for extra small screen ================= */

@media (max-width: 576px) {
  .navbar .logo {
    font-size: 20px;
  }
  .col-md-4:first-child {
    margin-right: 0;
  }
  .col-md-4:last-child {
    margin-left: 0;
  }
  .services .col-md-4 {
    margin: 2rem auto !important;
    width: 87.9% !important;
  }
  .col-md-4 {
    width: 100% !important;
  }
  .contact .col-md-4 {
    margin: none !important;
  }
  .col-md-3 {
    margin: 0 !important;
  }
  .about {
    gap: 2rem;
  }
  .about .about_content {
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    word-spacing: 5px;
  }
  .about .about_image {
    width: 300px;
    height: 200px;
    object-fit: cover;
  }

  footer .row {
    display: block;
  }
  .side1 {
    width: 100%;
  }
  .side2 {
    width: 100%;
  }
  .navopened ul {
    top: 55px !important;
  }
  .navopened {
    background-color: white !important;
  }
  .navopened .logo {
    color: #fafafa !important;
  }
  .navopened ul li a {
    color: #01bf71 !important;
  }
  .navopened .burger {
    background-color: #01bf71 !important;
  }
  .container {
    width: auto;
  }
  header h1 {
    font-size: 44px;
  }
  .btn {
    padding: 10px 30px;
  }
  .container {
    max-width: 540px;
  }

  .button {
    transform: translateX(0);
  }

  .navbar .logo {
    z-index: 3;
    color: #fafafa;
    padding-top: 0;
  }

  .burger {
    background-color: #fafafa;
  }
  .navbar {
    backdrop-filter: none;
    padding: 10px 0;
  }
  .navbar ul {
    backdrop-filter: blur(20px);

    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    list-style: none;
    padding: 0;
    text-align: center;
    margin: 0;
    padding-top: 10rem;
    transform: translateX(-100%);
  }

  .navbar ul li {
    margin: 2rem 0rem;
    display: block;
  }
  .navbar ul li a {
    cursor: pointer;
    font-size: 20px;
    text-decoration: none;
    color: #fafafa;
    font-weight: 500;
  }
  .col-md-6 {
    width: 100%;
  }
  .row {
    display: inline-block;
  }
}

.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
