ul li a {
  cursor: pointer;
}
.about {
  display: flex;
  gap: 4rem;
  margin-bottom: 6rem;
  text-align: start;
}
.about .about_image {
  width: 500px;
  height: 600px;
  object-fit: cover;
}
.about .about_content {
  color: #000000;
  font-size: 18px;
  /* font-weight: 500; */
  word-spacing: 10px;
  text-align: start;
}
