.card {
    text-align: center;
}
ul {
    text-align: start;
}
li {
    color: #01bf71;
    /* Bullet Color */
}
li span {
    color: black;
}
