.contact {
  text-align: center;
}
.contact-input {
  color: #01bf71;
  border-radius: 5px;
  padding: 15px 0px 10px 5px;
  font-size: 20px;
  width: 100%;
  outline-color: #01bf71;
  border: 0;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  margin-bottom: 10px;
}
.form-btn {
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: 20px;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  width: 70%;
  font-weight: 500;
  z-index: 1;
  background-color: #01bf71;
  padding: 10px 50px;

  transition: background-color 0.2s;
}
.contact-textarea {
  color: #01bf71;
  border-radius: 5px;
  padding: 15px 0px 10px 5px;
  font-size: 20px;
  height: 300px;
  min-height: 300px;
  max-height: 600px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  outline-color: #01bf71;
  border: 0;
  background-color: white;
}
 .input-container {
    width: 100%;
  }
  
  .contact-input,
  .contact-textarea {
    width: 70%;
    /* Add any other styling as needed */
  }