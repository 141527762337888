.container {
  text-align: center;
}
.card-title {
  color: #01bf71;
  font-size: 24px;
}

.p-3 {
  padding: 0.2rem 1rem;
}

.card-text {
  padding: 0rem 0rem 1rem 0rem;
  /* font-weight: 500; */
  font-size: 18px;
}

.card {
  cursor: pointer;
  border-radius: 10px;
  width: 600px;
  /* height: 100%; */
  transition: all 0.2s;
  background-color: white;
  box-shadow: 5px 5px 5px 5px #e5e5e5;
  margin-bottom: 20px;
}
.card-cover {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}
.card-row {
  text-align: center;
}

ul li a {
  cursor: pointer;
}
.hidden {
  display: none;
}