.process {
  display: flex;
  gap: 2rem;
  /* justify-content: center; */
  align-items: center;
}

.process .main-title {
  text-align: center;
  margin-top: 6rem;
}
.process .process_image {
  width: 600px;
}

.process .process_content {
  font-size: 20px;
  margin-bottom: 8px;
  /* font-weight: 500; */
}
