.choose {
  display: flex;
  gap: 2rem;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
}

.choose .main-title {
  text-align: center;
  margin-top: 6rem;
}
.choose .choose_image {
  width: 600px;
}

.choose .choose_content {
  font-size: 20px;
  margin-bottom: 12px;
  /* font-weight: 500; */
}
