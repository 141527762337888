.cover_image {
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
}

.headerbg {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 0;
  top: 0;
  background-color: black;
  opacity: 0.7;
}
header {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
header h1 {
  z-index: 1;
  color: white;
  font-size: 64px;
}
header h2 {
  color: white;
  z-index: 1;
  font-size: 60px;
}
header p {
  z-index: 1;
  color: white;
  font-size: 30px;
  text-align: center;
}
header h2 span {
  color: #01bf71;
}
.opened {
  transform: translateX(0%) !important;
}
