.text-white {
  color: white;
}
.footer-top {
  margin: 0;
  padding: 0.1rem 0;
  background-color: #01bf71;
}
.footer-top p {
  font-size: 18px;
}
.footer-text {
  margin-top: 2.5px;
  font-size: 18px;
  color: #919191;
}
footer {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 1rem 0;
  background-color: #010606;
}
footer .tax {
  color: #919191;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}
.side1 {
  width: 50%;
}
.side2 {
  width: 50%;
}
footer .col-md-3 {
  height: 100%;
}
footer .logo {
  transition: all 0.2s;
  margin-bottom: 0;
  align-items: center;
  align-content: center;
  color: #01bf71;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 28px;
}
footer .first_content {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center !important;
}
footer .company_logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-top: 8px;
}
.logo a {
  text-decoration: none;
  color: #fafafa;
}
.footer-title {
  color: #fafafa;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0;
  word-wrap: initial;
  text-align: start;
}
footer ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 5px;
}
footer ul li {
  margin-bottom: 8px;
}
footer ul li a {
  text-decoration: none;
  color: #919191;
  font-size: 18px;
  font-weight: 500;
}

.gotop {
  transition: all 0.2s;
  background-color: #01bf71;
  position: fixed;
  border-radius: 25px;
  padding: 1rem;
  border: 0;
  opacity: 0;
  outline: 0;
  cursor: pointer;
  font-weight: 1000;
  box-shadow: 0px 0px 5px 0.2px #000000;
  color: white !important;
  bottom: 20px;
  right: 15px;
}
/* start */