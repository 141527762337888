.navopened {
  background-color: white !important;
  color: #01bf71;
}
.navopened .logo {
  color: #01bf71 !important;
}
.navopened ul li a {
  color: #01bf71 !important;
}
.navopened .burger {
  background-color: #01bf71 !important;
}
.navbar {
  transition: background-color 0.4s;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0.5rem;
  background-color: transparent;
  z-index: 2;
  backdrop-filter: blur(2px);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.navbar .logo {
  transition: all 0.2s;
  align-items: center;
  align-content: center;
  color: rgb(243, 243, 243);
  letter-spacing: 2px;
  font-weight: 400;
  margin: 0;
  font-size: 25px;
}
.navbar ul {
  align-items: left;
  transition: all 0.2s;
  margin-left: 14rem;
  list-style: none;
}
.navbar ul li {
  margin: 0 1rem;
  display: inline-block;
}
.navbar ul li a {
  transition: all 0.2s;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  color: rgb(243, 243, 243);
  font-weight: 500;
}
.button {
  cursor: pointer;
  transition: transform 0.2s linear;
  position: fixed;
  transform: translateX(300%);
  top: 12px;
  right: 15px;
  z-index: 1;
}
.burger {
  transition: all 0.2s;
  margin-bottom: 0.45rem;
  width: 25px;
  height: 3px;
  background-color: white;
}
.scroll-progress {
  position: fixed;
  top: 0;
  left: 0;
  height: 5px;
  background-color: #01bf71; /* Change to desired color */
  z-index: 9999;
  transition: width 0.3s ease;
}
